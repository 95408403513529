import { Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addSavedJob, removeSavedJob } from '../../redux/reducers/savedJobsReducer';
import JobsService from '../../services/jobsService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import LinearFitScore from '../../shared/components/LinearFitScore';
import APIJob from '../../shared/models/APIJob';
import Job from '../../shared/models/Job';
import { AppColors } from '../../theme/AppTheme';
import { marginForIndex, widthForIndex } from './MessageCell';

interface DesktopJobCardProps {
    index: number;
    job: Job;
    jobData?: APIJob;
    minHeight?: string;
    onClick: () => void;
}
const DesktopJobCard = (props: DesktopJobCardProps) => {
    const { job, jobData, index, minHeight, onClick } = props;
    const savedJobs = useAppSelector(store => store.savedJobsState.jobsArray ?? []);
    const isSaved = savedJobs.some(j => (j.id === job.jobId) || (j.url === job.url));
    const [bookmarked, setBookmarked] = useState(isSaved);
    const savedJobsToggle = useAppSelector(store => store.savedJobsState.savedJobsToggle);

    const dispatch = useAppDispatch();

    const onBookmark = async () => {
        if (!bookmarked) {
            setBookmarked(true);
            const savedJob = await JobsService.saveJob(job);
            dispatch(addSavedJob(job));
            if (!savedJob) {
                setBookmarked(false);
            }
        } else {
            await JobsService.unsaveJob(job)
            dispatch(removeSavedJob(job));
            setBookmarked(false);
        }
    }

    useEffect(() => {
        setBookmarked(savedJobs.some(j => (j.id === job.jobId) || (j.url === job.url)));
    }, [savedJobsToggle]);

    const JobInfoCell = (props: { children: ReactNode, cellIndex: number }) => {
        const { children, cellIndex } = props;
        return (<div style={{ textAlign: 'left', minWidth: widthForIndex(cellIndex), maxWidth: widthForIndex(cellIndex), marginLeft: marginForIndex(cellIndex) }} key={cellIndex}>
            {children}
        </div>);
    }

    const jobLocations = jobData?.location.split(', ');
    const location1 = jobLocations ? (jobLocations.length > 0 ? jobLocations[0] : job.location1) : job.location1;
    const location2 = jobLocations ? (jobLocations.length > 1 ? jobLocations[1] : job.location2) : job.location2;


    return (
        <ClickableOpacityDiv gradientBorder onClick={onClick} key={index} style={{ position: 'relative', minHeight: minHeight, maxHeight: '120px', overflow: 'hidden', display: 'flex', padding: '30px', alignItems: 'center', backgroundColor: AppColors.white, boxShadow: `0 0 2px 1px ${AppColors.card}`, marginBottom: '16px', borderRadius: '12px', }}>
            <JobInfoCell cellIndex={0}><LinearFitScore percentage={job.fit} /></JobInfoCell>

            {/* Job Title */}
            <JobInfoCell cellIndex={1}>
                <>
                    <Typography variant='body2' style={{ whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {jobData?.title ?? job.title}<br />
                    </Typography>
                    <Typography variant='h5' style={{ color: AppColors.grey.darkish, marginTop: '4px', whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {jobData?.company ?? job.company}
                    </Typography>
                </>
            </JobInfoCell>

            {/* Location */}
            <JobInfoCell cellIndex={2}>
                <>
                    <Typography variant='body2' style={{ whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {location1}
                    </Typography>
                    {job.location2 && <Typography variant='h5' style={{ color: AppColors.grey.darkish, marginTop: '4px', whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {location2}
                    </Typography>}
                </>
            </JobInfoCell>

            {/* Skills */}
            <JobInfoCell cellIndex={3}>
                <Typography
                    variant='h5'
                    style={{
                        // whiteSpace: 'nowrap',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxHeight: '2.4em',
                        lineHeight: '1.2em',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        minWidth: '130px',
                    }}>
                    {(!!job.skills && job.skills.trim().length > 0) ? job.skills : 'Not specified'}
                </Typography>
                {/* <ClickableOpacityDiv
                    onClick={(e) => { e.stopPropagation(); onBookmark(); }}
                    style={{
                        position: 'absolute',
                        right: '20px',
                        top: '20px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {bookmarked ? <SavedIcon /> : <SaveIcon />}
                </ClickableOpacityDiv> */}
            </JobInfoCell>

        </ClickableOpacityDiv>
    )
}

export default DesktopJobCard