import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducers/appReducer';
import documentsReducer from './reducers/documentsReducer';
import interviewReducer from './reducers/interviewReducer';
import promptsReducer from './reducers/promptReducer';
import resourceReducer from './reducers/resourceReducer';
import savedJobsReducer from './reducers/savedJobsReducer';
import savedResumesReducer from './reducers/savedResumeReducer';
import userReducer from './reducers/userReducer';

const store = configureStore({
    reducer: {
        userState: userReducer,
        appState: appReducer,
        resourceState: resourceReducer,
        documentState: documentsReducer,
        promptState: promptsReducer,
        interviewState: interviewReducer,
        savedResumesState: savedResumesReducer,
        savedJobsState: savedJobsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
