import { auth } from "../firebaseConfigs";
import UserInfo from "../profile/models/UserInfo";
import IResume from "../shared/interfaces/IResume";
import IUser from "../shared/interfaces/IUser";
import User from "../shared/models/User";

class UserService {
    static fetchSelf = async (): Promise<User | undefined> => {
        const token = await auth.currentUser?.getIdToken();
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/me`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        if (res.status === 404) {
            return undefined;
        } else if (res.status !== 200) {
            throw new Error(`Failed to fetch user: ${res.status}`);
        }
        const jsonBody: IUser = await res.json();
        const user = new User(jsonBody);

        return user;
    }

    static createUser = async (info?: Partial<IUser>): Promise<User> => {
        const token = await auth.currentUser?.getIdToken();
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: !!info ? JSON.stringify(info) : undefined,
        });
        const jsonBody: IUser = await res.json();
        const user = new User(jsonBody);
        return user;
    }

    static updateUser = async (info?: Partial<IUser>): Promise<User> => {
        const token = await auth.currentUser?.getIdToken();
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/update`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: !!info ? JSON.stringify(info) : undefined,
        });
        const jsonBody: IUser = await res.json();
        const user = new User(jsonBody);
        return user;
    }

    static uploadResume = async (file: File): Promise<User> => {
        const token = await auth.currentUser?.getIdToken();
        const formData = new FormData();
        formData.append('resume', file);

        const res = await fetch(`${process.env.REACT_APP_API_URL}/resumes/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        });

        if (res.status !== 200) {
            throw new Error(`Failed to upload resume: ${res.status}`);
        }

        const responseJson = await res.json();
        return new User(responseJson);
    }

    static updateResume = async (resume: Partial<IResume>): Promise<IResume> => {
        const token = await auth.currentUser?.getIdToken();

        const res = await fetch(`${process.env.REACT_APP_API_URL}/resumes/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(resume),
        });

        if (res.status !== 200) {
            throw new Error(`Failed to upload resume: ${res.status}`);
        }

        const responseJson: IResume = await res.json();
        return responseJson;
    }

    static deleteResume = async (): Promise<User> => {
        const token = await auth.currentUser?.getIdToken();

        const res = await fetch(`${process.env.REACT_APP_API_URL}/resumes/remove`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (res.status !== 200) {
            throw new Error(`Failed to delete resume: ${res.status}`);
        }

        const responseJson = await res.json();
        return new User(responseJson);
    }

    static updateUserInfo = async (info: UserInfo): Promise<User> => {
        const token = await auth.currentUser?.getIdToken();

        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/info/update`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(cleanInfo(info)),
        });

        if (res.status !== 200) {
            throw new Error(`Failed to update user info: ${res.status}`);
        }

        const responseJson = await res.json();
        return new User(responseJson);
    }

    static disableTwoFactor = async (): Promise<void> => {
        const token = await auth.currentUser?.getIdToken();

        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/2fa/remove`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (res.status !== 200) {
            throw new Error(`Failed to remove 2fa: ${res.status}`);
        }
    }

}

function cleanInfo(info: UserInfo): any {
    return {
        desired_positions: info.desiredPositions?.join('||'),
        desired_income: info.desiredIncome,
        work_environments: info.preferredWorkEnvironments?.join('||'),
        skills: info.skills?.join('||'),
        preferred_locations: info.preferredLocations?.join('||'),
    }
}


export default UserService;
