import styled from '@emotion/styled';
import { TurnSharpLeftRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useAppSelector } from '../redux/hooks';
import ClickableOpacityDiv from '../shared/components/ClickableOpacityDiv';
import { StyledBackButton, StyledCloseButton } from '../shared/components/SideBarButtons';
import { AppColors } from '../theme/AppTheme';

const Sidebar = styled('div')((props: { width: number, isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingLeft: props.isMobile ? '24px' : '40px',
    paddingTop: props.isMobile ? '72px' : '52px',
    width: props.isMobile ? '100%' : `${props.width}px`,
    height: props.isMobile ? 'calc(100% - 72px)' : '100%',
    transition: `width 0.2s ${TurnSharpLeftRounded}`,
    borderRight: `1px solid ${AppColors.grey.light}`,
    overflow: 'hidden',
    backgroundColor: AppColors.white,
}));

const tabs = [
    'Personal info',
    'Resume',
    'Authentication',
    'Support',
    'Privacy',
    'Terms of service',
    'Admin',
    'Manage Prompts',
    'Logout',
]

interface ProfileSidebarProps {
    width: number;
    tab: number;
    setTab: (index: number) => void;
    logout: () => void;
}

const TabButton = (props: { selectedTab: number, name: string, index: number, onClick: () => void }) => {
    return (
        <ClickableOpacityDiv onClick={props.onClick} >
            <Typography variant='h4' style={{ lineHeight: '24px', marginBottom: '24px', color: props.selectedTab === props.index ? AppColors.black : AppColors.grey.dark, whiteSpace: 'nowrap' }}>
                {props.name}
            </Typography>
        </ClickableOpacityDiv>
    );
}

const ProfileSidebar = (props: ProfileSidebarProps) => {
    const { width, tab, setTab, logout } = props;
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    return (
        <Sidebar width={width} isMobile={isMobile}>
            {isMobile && <StyledBackButton />}
            {isMobile && <StyledCloseButton />}
            <Typography variant={isMobile ? 'h2' : 'h4'} style={{ fontSize: isMobile ? '18px' : '14px', marginBottom: isMobile ? '38px' : '58px', whiteSpace: 'nowrap' }}>
                Profile
            </Typography>
            {tabs.map((tabName, index) => (
                <TabButton key={index} selectedTab={tab} name={tabName} index={index} onClick={
                    index === 3 ? () => window.open('https://pathpilot.freshdesk.com/support/home', '_blank') :
                        index === 8 ? logout : () => setTab(index)
                } />
            ))}
        </Sidebar>
    )
}

export default ProfileSidebar