import { ReactNode } from "react";
import { generatePromptInfo } from "../../profile/models/UserInfo";
import { RootState } from "../../redux/store";
import UpskillingType from "../../shared/enums/UpskillingType";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import MessageCell from "../components/MessageCell";
import SavedJobSelection, { SavedJobSelectionType } from "../components/SavedJobSelection";
import UpskillingTypeSelection from "../components/UpskillingTypeSelection";


export const messageCellForUpskillingChat = (message: Message, type?: UpskillingType): ReactNode => {
    if (!type) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                isNewest={true}
                accessory={<><br /><UpskillingTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    switch (type) {
        case UpskillingType.specificRole:
            messageText = `What type of job / role are you looking to upskill for?`;
            break;
        case UpskillingType.savedJobs:
            messageText = `Which saved job would you like to upskill for?`;
            accessory = <SavedJobSelection type={SavedJobSelectionType.upskilling} />;
            break;

    }
    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
        />
    </>);

}

export const titleForUpskillingType = (type: UpskillingType): string => {
    switch (type) {
        case UpskillingType.resumeAndPreferences:
            return 'Upskill based on Resume and Preferences';
        case UpskillingType.savedJobs:
            return 'Upskill for Saved Job';
        case UpskillingType.specificRole:
            return 'Upskill for Specific Job / Role';
    }
}

export const initialMessageForUpskillType = (type: UpskillingType): string => {
    switch (type) {
        case UpskillingType.specificRole:
            return `I'd like to upskill for a specific job /role.`;
        default:
            return '';
    }
}

export const upskillStartMessage = (type: UpskillingType, state: RootState, job?: Job, role?: string): string => {
    const userInfo = state.userState.currentUser?.info;
    const skills = generatePromptInfo(userInfo);
    switch (type) {
        case UpskillingType.resumeAndPreferences:
            return `[UPSKILL_GENERAL]\n\nWhat skills can I develop to improve my career prospects?\n\nBased on my resume, what hard skills and soft skills would you suggest I learn in order to improve my marketability and career prospects? For each area please suggest specific resources for up-skilling in those areas along with links to the specific courses you mention, their price range and approximate time required ideally in a table format. Please also add in a skill gap score and a skill value score for each and explain what those mean.Fetch the courses using a function call. Here are my current career objectives: ${skills}, and here is my resume: ${state.userState.currentUser?.resume?.textResume}`;
        case UpskillingType.savedJobs:
            return `[UPSKILL_SAVED]\n\nWhat skills can I develop to improve my career prospects for the job **${job?.title}** at **${job?.company}**.\n\n Job Description: ${job?.summary}?\n\nWhat hard skills and soft skills would you suggest I learn in order to be the ideal candidate for the given job? For each area please suggest specific resources for up-skilling in those areas along with links to the specific courses you mention, their price range and approximate time required ideally in a table format. Please also add in a skill gap score and a skill value score for each and explain what those mean.Fetch the courses using a function call.Here are my current career objectives: ${skills}, and here is my resume: ${state.userState.currentUser?.resume?.textResume}`;
        case UpskillingType.specificRole:
            return `[UPSKILL_ROLE]\n\nWhat skills can I develop to improve my career prospects for the role **${role}**.\n\nWhat hard skills and soft skills would you suggest I learn in order to be the ideal candidate for the given role? For each area please suggest specific resources for up-skilling in those areas along with links to the specific courses you mention, their price range and approximate time required ideally in a table format. Please also add in a skill gap score and a skill value score for each and explain what those mean.Fetch the courses using a function call.Here are my current career objectives: ${skills}, and here is my resume: ${state.userState.currentUser?.resume?.textResume}`;
    }
}
