import { initialMessageForJobMatchType, jobMatchStartMessage, titleForJobMatchType } from "../../chat/utils/JobMatchHelper";
import JobMatchType from "../../shared/enums/JobMatchType";
import { newJobMatchChat } from "../../shared/models/Chat";
import Job from "../../shared/models/Job";
import Message, { MessageType } from "../../shared/models/Message";
import { focusText, setActiveChat } from "../reducers/appReducer";
import { AppDispatch, RootState } from "../store";
import { sendMessageAction } from "./ChatActions";

export const StartJobMatchFlowAction = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const message: Message = {
                content: `How would you like to find jobs?`,
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }
            const newChat = newJobMatchChat([message], `Perform a Job Search`);
            dispatch(setActiveChat(newChat));
        } catch (error) {
            console.error('Error starting job match chat:', error);
        }
    };
}

export const SetJobMatchTypeAction = (type: JobMatchType) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;
            if (!chat) return;
            if (type == JobMatchType.resumeAndPreferences || type == JobMatchType.advice) {
                dispatch(StartJobMatchAction(type));
                return;
            }

            // Recreate the chat with the new job match type
            const selectedMessage: Message = {
                content: initialMessageForJobMatchType(type),
                dt: (new Date()).toISOString(),
                type: MessageType.sent
            }
            const message: Message = {
                content: '',
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }

            const newChat = newJobMatchChat([selectedMessage, message], titleForJobMatchType(type), type);
            dispatch(setActiveChat({ ...newChat, sessionTitle: titleForJobMatchType(type) }));
            dispatch(focusText());
        } catch (error) {
            console.error('Error setting job match type:', error);
        }
    };
}

export const StartJobMatchAction = (type: JobMatchType, job?: Job, role?: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            dispatch(sendMessageAction(jobMatchStartMessage(type, state, job, role), true));
        } catch (error) {
            console.error('Error starting job match chat:', error);
        }
    };
}