import styled from "@emotion/styled";
import { Modal } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import FAQsView from "../mainTabs/faqs/FAQsView";
import { LogoutAction } from "../redux/actions/GlobalActions";
import { useAppDispatch } from "../redux/hooks";
import ConfirmationModal from "../shared/components/ConfirmationModal";
import AdminSettingsPage from "./AdminSettingsPage";
import AuthenticationPage from "./AuthenticationPage";
import PrivacyView from "./PrivacyView";
import ProfilePage from "./ProfilePage";
import ProfileSidebar from "./ProfileSidebar";
import PromptPage from "./PromptPage";
import ResumePage from "./ResumePage";
import TermsView from "./TermsView";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    height: '100vh',
}));



export const ProfileTabForIndex = (index: number): ReactNode => {
    switch (index) {
        case 0:
            return <ProfilePage />;
        case 1:
            return <ResumePage />;
        case 2:
            return <AuthenticationPage />;
        case 3:
            return <FAQsView />;
        case 4:
            return <PrivacyView />;
        case 5:
            return <TermsView />;
        case 6:
            return <AdminSettingsPage />
        case 7:
            return <PromptPage />;

    }
    return <ProfilePage />;
}

const ProfileNavigationPage = () => {
    const [tab, setTab] = useState(0);
    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState(0);
    const dispatch = useAppDispatch();

    const logout = () => {
        setOpen(true);
    }

    useEffect(() => {
        setWidth(260);
    }, [])

    return (
        <StyledRow>
            <ProfileSidebar width={width} tab={tab} setTab={setTab} logout={logout} />
            {ProfileTabForIndex(tab)}
            <Modal
                open={open}>
                <ConfirmationModal title="Log out?" subtitle="Are you sure you want to log out?" onCancel={() => { setOpen(false) }} onConfirm={() => { setOpen(false); dispatch(LogoutAction()); }} confirmText="Yes" />
            </Modal>
        </StyledRow>
    )

}

export default ProfileNavigationPage