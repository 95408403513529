import UserInfo from "../../profile/models/UserInfo";
import IResume from "../interfaces/IResume";
import IUser from "../interfaces/IUser";
import Resource from "./Resource";
import SavedChat from "./SavedChat";

export default class User implements IUser {
    firstName?: string;
    lastName?: string;
    email: string;
    avatarUrl?: string;
    address?: string;
    phone?: string;
    resume?: IResume;
    info?: UserInfo;
    savedResources: Resource[] = [];
    savedChats: SavedChat[] = [];
    marketing: boolean;
    onboarded: boolean;

    constructor(props: IUser) {
        const { firstName, lastName, email, avatarUrl, address, phone, resume, info, savedResources, savedChats, marketing, onboarded } = props;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.avatarUrl = avatarUrl;
        this.address = address;
        this.phone = phone;
        this.resume = resume;
        this.info = info
        this.savedResources = savedResources ?? [];
        this.savedChats = savedChats ?? [];
        this.marketing = marketing;
        this.onboarded = onboarded;

        // Handle legacy info
        if ((this.info?.skills?.length ?? 0) > 0) {
            const firstSkill = this.info!.skills![0];
            if (firstSkill.includes(',')) {
                this.info!.skills = firstSkill.split(',').map(skill => skill.trim());
            }
        }
    }

    get initials(): string {
        const firstInitial = !!this.firstName ? this.firstName[0].toUpperCase() : '';
        const lastInitial = !!this.lastName ? this.lastName[0].toUpperCase() : '';
        return `${firstInitial}${lastInitial}`;
    }

    get name(): string {
        return `${this.firstName}${this.lastName ? ` ${this.lastName}` : ''}`;
    }

    serialize() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            avatarUrl: this.avatarUrl,
            address: this.address,
            phone: this.phone,
            marketing: this.marketing,
            onboarded: this.onboarded,
        };
    }
}
