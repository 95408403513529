import styled from "@emotion/styled";
import { Theme, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Bars } from "react-loader-spinner";
import { Strings } from "../../i18n";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setUser } from "../../redux/reducers/userReducer";
import UserService from "../../services/userService";
import CloseButton from "../../shared/components/CloseButton";
import FullScreenModalDiv from "../../shared/components/FullScreenModalDiv";
import OutlinedButton from "../../shared/components/OutlinedButton";
import PrimaryButton from "../../shared/components/PrimaryButton";
import User from "../../shared/models/User";
import { AppColors } from "../../theme/AppTheme";

const Input = styled('textarea')((props: { theme: Theme, isMobile: boolean }) => ({
    width: props.isMobile ? 'calc(100% - 40px)' : '100%',
    height: props.isMobile ? '100%' : '50vh',
    minHeight: props.isMobile ? 'calc(100% - 140px)' : '200px',
    padding: '20px',
    border: `1px solid ${AppColors.grey.border}`,
    borderRadius: '12px',
    resize: 'none',
    outline: 'none',
    fontFamily: props.theme.typography.h5.fontFamily,
    fontSize: props.theme.typography.h5.fontSize,
    lineHeight: '24px',
    color: AppColors.black,
    display: 'flex',
    flexGrow: 1,
    '::placeholder': {
        color: AppColors.grey.dark,
    },
}));

const StyledContainer = styled('div')((props: { isMobile: boolean }) => ({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '100%' : '70%',
    height: props.isMobile ? '100%' : undefined,
    maxWidth: props.isMobile ? '100vw' : '70vw',
    maxHeight: props.isMobile ? '100%' : '85vh',
    backgroundColor: AppColors.white,
}));

const StyledScrollingContainer = styled('div')((props: { isMobile: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    height: props.isMobile ? 'calc(100% - 48px)' : undefined,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '24px' : '54px 40px',
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '20px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '22px',
    width: props.isMobile ? 'calc(100% - 40px)' : '100%',
}));

interface EditTextResumeModalProps {
    onClose: () => void;
}

const EditTextResumeModal = (props: EditTextResumeModalProps) => {
    const { onClose } = props;
    const user = useAppSelector(store => store.userState.currentUser);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [resumeText, setResumeText] = useState(user?.resume?.textResume ?? '');
    const dispatch = useAppDispatch();
    const theme = useTheme();


    const onSubmitPressed = async () => {
        setLoading(true);
        try {
            const updatedResume = await UserService.updateResume({ id: user?.resume?.id ?? 0, textResume: resumeText });
            const newUserData = { ...user!, resume: updatedResume };
            const newUser = new User(newUserData);
            dispatch(setUser(newUser));
            onClose();
        } catch (e) {
            console.error(`Error: ${e}`);
        } finally {
            setLoading(false);
        }
    }

    const onChange = (newText: string) => {
        setResumeText(newText);
    }

    return (
        <FullScreenModalDiv>
            <StyledContainer isMobile={isMobile}>
                <StyledScrollingContainer isMobile={isMobile}>
                    <CloseButton onClick={onClose} />
                    <Typography variant='h2' style={{ marginBottom: '21px' }}>
                        Edit Text Resume
                    </Typography>
                    <Typography variant='h5' style={{
                        textAlign: 'center',
                        marginBottom: '40px',
                        color: AppColors.grey.darkish
                    }}>
                        Ensure your resume is accurate.
                    </Typography>
                    <Input
                        autoComplete="off"
                        isMobile={isMobile}
                        theme={theme}
                        value={resumeText}
                        onChange={(e) => onChange(e.target.value)}
                    />
                </StyledScrollingContainer>
                <StyledButtons isMobile={isMobile}>
                    <PrimaryButton height={'50px'} style={{ maxWidth: '294px', }} title={t(Strings.save).toUpperCase()} disabled={false} onClick={onSubmitPressed} />
                    <OutlinedButton height={'50px'} style={{ maxWidth: '294px' }} title={t(Strings.cancel).toUpperCase()} disabled={false} onClick={onClose} />
                </StyledButtons>
                {loading && <div style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 999,
                    backgroundColor: AppColors.loading,
                }}>
                    <>
                        <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                        <Bars
                            height="30"
                            width="30"
                            color={AppColors.pink.dark}
                            ariaLabel="bars-loading"
                            visible={true}
                        />
                    </>
                </div>}
            </StyledContainer>

        </FullScreenModalDiv>
    )
}

export default EditTextResumeModal;