import styled from "@emotion/styled";
import { Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "../../assets/icons/edit_icon";
import { Strings } from "../../i18n";
import { useAppSelector } from "../../redux/hooks";
import { AppColors } from "../../theme/AppTheme";
import JobInfoModal from "../modals/JobInfoModal";
import { userInfoOrder } from "../models/UserInfo";
import ProfileSectionHeader from "./ProfileSectionHeader";

const StyledColumn = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '80px',
}));

interface ProfileSectionProps {
    stacked?: boolean;
}

const JobProfileSection = (props: ProfileSectionProps) => {
    const { stacked } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const data = useAppSelector(store => store.userState.currentUser?.info);
    const isMobile = useAppSelector(store => store.appState.isMobile);

    const onEditPressed = () => {
        setOpen(true);
    }


    const convertCamelCaseToTitle = (value: string) => {
        const result = value.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const formatValue = (value: string | string[] | undefined): string => {
        if (Array.isArray(value)) {
            return value.join(', ');
        }
        return value ?? '';
    }
    const unwrappedData = data ?? {};
    return (
        <StyledColumn>
            <ProfileSectionHeader title={'Job Preferences'} icon={<EditIcon />} action={t(Strings.edit)} onActionPressed={onEditPressed} />
            {userInfoOrder.map((key) => {
                if (key === 'skills') {
                    return null;
                }
                let value: any = '';
                if (key in unwrappedData) {
                    // @ts-ignore
                    value = unwrappedData[key];
                }
                return (
                    <div key={key} style={{ display: 'flex', flexDirection: `${stacked ? 'column' : 'row'}`, alignItems: 'flex-start', justifyContent: 'flex-start', paddingTop: isMobile ? '25px' : '40px' }}>
                        <Typography variant="h4" style={{ textAlign: 'left', width: stacked ? '100%' : isMobile ? '100px' : '200px', minWidth: isMobile ? '100px' : '210px' }}>
                            {convertCamelCaseToTitle(key)}:
                        </Typography>
                        <Typography variant="subtitle2" style={{ marginTop: stacked ? '15px' : 0, lineHeight: '30px', textAlign: 'left', paddingLeft: stacked ? 0 : isMobile ? 'clamp(24px, 60px, 140px)' : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish }}>
                            {formatValue(value)}
                        </Typography>
                    </div>
                );
            }
            )}
            <Modal open={open}>
                <JobInfoModal
                    initialInfo={data ?? {}}
                    onDonePressed={() => setOpen(false)}
                    onBackPressed={() => setOpen(false)}
                />
            </Modal>
        </StyledColumn>
    )
}

export default JobProfileSection