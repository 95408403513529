import { ReactNode } from "react";
import { RootState } from "../../redux/store";
import InterviewQuestionsType from "../../shared/enums/InterviewQuestionsType";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import InterviewQuestionsTypeSelection from "../components/InterviewQuestionsTypeSelection";
import MessageCell from "../components/MessageCell";
import SavedJobSelection, { SavedJobSelectionType } from "../components/SavedJobSelection";


export const messageCellForInterviewQuestionsChat = (message: Message, type?: InterviewQuestionsType): ReactNode => {
    if (!type) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                isNewest={true}
                accessory={<><br /><InterviewQuestionsTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    switch (type) {
        case InterviewQuestionsType.job:
            messageText = `Which saved job would you like interview questions for?`;
            accessory = <SavedJobSelection type={SavedJobSelectionType.interviewQuestions} />;
            break;
        case InterviewQuestionsType.specified:
            messageText = `What type of job / role would you like interview questions for?`;
            break;

    }
    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
        />
    </>);

}

export const titleForInterviewQuestionsType = (type: InterviewQuestionsType): string => {
    switch (type) {
        case InterviewQuestionsType.resume:
            return 'Interview Prep Based on Resume';
        case InterviewQuestionsType.job:
            return 'Saved Job Interview Prep';
        case InterviewQuestionsType.specified:
            return 'Specific Role Interview Prep';
    }
}

export const initialMessageForInterviewQuestionsType = (type: InterviewQuestionsType): string => {
    switch (type) {
        case InterviewQuestionsType.job:
            return `I'd like interview questions to prepare for one of my saved jobs.`;
        case InterviewQuestionsType.specified:
            return `I'd like interview questions to prepare for a specific role or position that I'm interested in.`;
        default:
            return '';
    }
}

export const interviewQuestionsMessage = (type: InterviewQuestionsType, state: RootState, job?: Job, role?: string): string => {
    const userSkills = state.userState.currentUser?.info?.skills;
    switch (type) {
        case InterviewQuestionsType.resume:
            return `[QUESTIONS_GENERAL]\n\nGive me a list of interview questions I should be prepared for based on my resume.\n\n ${state.userState.currentUser?.resume?.textResume}\n\n${!!userSkills ? `Skills: ${userSkills}` : ''}`;
        case InterviewQuestionsType.job:
            return `[QUESTIONS_JOB]\n\nGive me a list of interview questions for the job: **${job?.title}** at **${job?.company}**.\n\nJob Description: ${job?.summary}`;
        case InterviewQuestionsType.specified:
            return `[QUESTIONS_ROLE]\n\nGive me a list of interview questions I should prepare for to interview for the role **${role}**.`;
    }
}
