import { Auth, MultiFactorError, createUserWithEmailAndPassword, getMultiFactorResolver, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfigs";
import UserService from "../../services/userService";
import { clearAuthError, doneInitializing, set2FALoginInfo, setAuthError, setAuthIsBusy, setEmailVerificationSent, setOnboarding, setUser } from "../reducers/userReducer";
import { AppDispatch, RootState } from "../store";
import { GetChatHistoryAction } from "./ChatActions";
import { FetchInterviewsAction } from "./InterviewActions";
import { FetchSavedJobsAction } from "./SavedJobsActions";
import { FetchResourceListsAction, SetupSavedResourceBuckets } from "./SavedResourceActions";
import { FetchSavedResumesAction } from "./SavedResumeActions";

export const LogInAction = (auth: Auth, email: string, password: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(clearAuthError(undefined));
            dispatch(setAuthIsBusy(true));
            await signInWithEmailAndPassword(auth, email, password);
            const user = await UserService.fetchSelf();
            if (!user) throw new Error('User not found');
            dispatch(setUser(user));
            dispatch(GetChatHistoryAction());
            dispatch(FetchResourceListsAction());
            dispatch(FetchInterviewsAction());
            dispatch(FetchSavedResumesAction());
            dispatch(SetupSavedResourceBuckets(user.savedResources));
        } catch (error) {
            let errorMessage = "Something went wrong. Check your info and try again.";
            if (typeof error === 'object' && error !== null && 'code' in error) {
                const firebaseError = error as { code?: string };
                switch (firebaseError.code) {
                    case 'auth/multi-factor-auth-required':
                        const resolver = getMultiFactorResolver(auth, error as MultiFactorError);
                        dispatch(set2FALoginInfo({ resolver }));
                        return;
                    case 'auth/invalid-email':
                        errorMessage = 'Invalid email address';
                        break;
                    case 'auth/user-disabled':
                        errorMessage = 'User account is disabled';
                        break;
                    case 'auth/user-not-found':
                        errorMessage = 'User not found';
                        break;
                    case 'auth/invalid-credential':
                        errorMessage = 'Invalid credentials';
                        break;
                    case 'auth/wrong-password':
                        errorMessage = 'Incorrect password';
                        break;
                    default:
                        errorMessage = 'Unknown error occurred';
                        break;
                }
            }
            dispatch(setAuthError(errorMessage));
            console.error('Error logging in:', error);
        } finally {
            dispatch(setAuthIsBusy(false));
        }
    };
};

export const VerifyEmailAction = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const verificationSent = getState().userState.emailVerifcationSent;
        const user = auth.currentUser;
        if (!verificationSent && user && !user.emailVerified) {
            dispatch(setEmailVerificationSent(true));
            await sendEmailVerification(user, { url: window.location.href });
        }
    };
};

let fetchingUser = false;
export const SetCachedUserAction = () => {
    return async (dispatch: AppDispatch) => {
        if (fetchingUser) return;
        fetchingUser = true;
        try {
            dispatch(setAuthIsBusy(true));
            const user = await UserService.fetchSelf();
            // User doesn't exist in backend
            if (!user) return;
            dispatch(setUser(user));
            dispatch(GetChatHistoryAction());
            dispatch(FetchResourceListsAction());
            dispatch(FetchInterviewsAction());
            dispatch(FetchSavedResumesAction());
            dispatch(FetchSavedJobsAction());
            dispatch(SetupSavedResourceBuckets(user.savedResources));
            if (!user.onboarded) {
                dispatch(setOnboarding(true));
            }
        } catch (error) {
            dispatch(setAuthError(`${error}`));
            console.error('Error logging in:', error);
            auth.signOut();
        } finally {
            dispatch(setAuthIsBusy(false));
            dispatch(doneInitializing());
            fetchingUser = false;
        }
    };
};

export const CreateUserAction = (auth: Auth, email: string, password: string, name: string, marketing: boolean) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setOnboarding(true));
            dispatch(setAuthIsBusy(true));
            await createUserWithEmailAndPassword(auth, email, password);
            let firstName = '';
            let lastName = '';

            if (name.trim() !== '') {
                const nameParts = name.split(' ');
                firstName = nameParts[0];
                lastName = nameParts.slice(1).join(' ');
            }
            const user = await UserService.createUser({ firstName, lastName, marketing });
            dispatch(setUser(user));
        } catch (error) {
            let errorMessage = 'Something went wrong. Check your info and try again.';
            console.error(error);
            if (typeof error === 'object' && error !== null && 'code' in error) {
                const firebaseError = error as { code?: string };
                switch (firebaseError.code) {
                    case 'auth/email-already-in-use':
                        errorMessage = 'This email is already in use.';
                        break;
                    case 'auth/invalid-email':
                        errorMessage = 'The email address is not valid.';
                        break;
                    case 'auth/operation-not-allowed':
                        errorMessage = 'Email/password accounts are not enabled.';
                        break;
                    case 'auth/weak-password':
                        errorMessage = 'The password is too weak.';
                        break;
                }
            } else if (typeof error === 'string') {
                errorMessage = error;
            }
            dispatch(setAuthError(errorMessage));
            console.error('Error creating user:', error);
        } finally {
            dispatch(setAuthIsBusy(false));
        }
    };
};