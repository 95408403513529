import { ReactNode } from "react";
import { generatePromptInfo } from "../../profile/models/UserInfo";
import { RootState } from "../../redux/store";
import JobMatchType from "../../shared/enums/JobMatchType";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import JobMatchTypeSelection from "../components/JobMatchTypeSelection";
import MessageCell from "../components/MessageCell";


export const messageCellForJobMatchChat = (message: Message, jobMatchType?: JobMatchType): ReactNode => {
    if (!jobMatchType) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                isNewest={true}
                accessory={<><br /><JobMatchTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    switch (jobMatchType) {
        case JobMatchType.specificRole:
            messageText = `What type of job / role are you looking for?`;
            break;

    }
    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
        />
    </>);

}

export const titleForJobMatchType = (type: JobMatchType): string => {
    switch (type) {
        case JobMatchType.resumeAndPreferences:
            return 'Job Match based on Resume and Preferences';
        case JobMatchType.advice:
            return 'Job Advice';
        case JobMatchType.specificRole:
            return 'Specific Job / Role';
    }
}

export const initialMessageForJobMatchType = (type: JobMatchType): string => {
    switch (type) {
        case JobMatchType.specificRole:
            return `Find me jobs for a specific role or position.`;
        default:
            return '';
    }
}

export const jobMatchStartMessage = (type: JobMatchType, state: RootState, job?: Job, role?: string): string => {
    const userInfo = state.userState.currentUser?.info;
    switch (type) {
        case JobMatchType.resumeAndPreferences:
            const skills = generatePromptInfo(userInfo);
            return `[JOB_MATCH_GENERAL]\n\nCan you provide a list of jobs that are a great match for me?\n\nGiven my current career objectives: ${skills} return the top jobs matching them. Fetch my job search criteria if needed. When returning job positions, force-rank the jobs and provide job title, a link to the job, company name, location, fit score, and key skills, and output this information in a table format. Make the fit score the first column, and make sure the fit score is out of 100.Use the headers "Job Title", "Fit Score", "Company", "Location", and "Skills".}`;
        case JobMatchType.advice:
            return `[JOB_MATCH_ADVICE]\n\nProvide me with advice on the types of jobs I should be looking for based on my skills and experience.\n\nPlease conduct a comprehensive analysis of my resume and assess my skills, experiences, and accomplishments. Based on this analysis, provide targeted recommendations for job positions I should actively pursue, highlighting how my background aligns with these roles. Additionally, identify alternative career paths where my skills could be valuable and transferable, along with brief justifications for these suggestions. I'm particularly interested in understanding the nuances of how my expertise can be leveraged in various job markets.\n\nMy Resume: ${state.userState.currentUser?.resume?.textResume}\n\nMy Skills: ${userInfo?.skills}`;
        case JobMatchType.specificRole:
            return `[JOB_MATCH_ROLE]\n\nProvide me with job matches for the role **${role}**.\n\n Don't use my job search preference, just use the role I provided`;
    }
}
