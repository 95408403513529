import styled from "@emotion/styled";
import { MenuItem, Select, Switch, SwitchProps, Typography, styled as muiStyled } from "@mui/material";
import { CSSProperties } from "react";
import packageJson from '../../package.json';
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { resetJobSummaryMessage, resetJobSummaryPreamble, setChatModel, setDebugMode, setJobSummaryMessage, setJobSummaryModel, setJobSummaryPreamble, setSuggModel, setUseSuggestedPrompts } from "../redux/reducers/appReducer";
import { resetResumePrompt, setInterviewModel, setResumeModel, setResumePrompt } from "../redux/reducers/promptReducer";
import ClickableOpacityDiv from "../shared/components/ClickableOpacityDiv";
import PPMultiLineTextInput from "../shared/components/PPMultiLIneTextInput";
import LLMModel from "../shared/models/LLMModels";
import { AppColors } from "../theme/AppTheme";
import AdminSettingRow from "./components/AdminSettingRow";
import ProfileSectionHeader from "./components/ProfileSectionHeader";

const IOSSwitch = muiStyled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: '12px',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: AppColors.pink.dark,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: AppColors.grey.darkish,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const ScrollingWrapper = styled('div')(() => ({
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
}));

const StyledContainer = styled('div')((props: { style?: CSSProperties, isMobile: boolean }) => ({
    padding: props.isMobile ? '24px' : '50px',
    maxWidth: '1200px',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    ...props.style,
}));

const Divider = () => {
    return <div style={{ marginTop: '12px', width: '100%', minHeight: '1.5px', backgroundColor: AppColors.separator }} />
}

const SectionTitle = (props: { title: string, top?: boolean }) => {
    return <div style={{ marginTop: props.top ? '40px' : '100px' }}>
        <Typography variant="h4" style={{ textAlign: 'left', color: AppColors.link, fontWeight: 500, fontSize: '18px' }}>
            {props.title}
        </Typography>
        <Divider />
    </div>
}

const AdminSettingsPage = () => {
    const currentChatModel = useAppSelector(store => store.appState.chatModel);
    const currentSuggModel = useAppSelector(store => store.appState.suggModel);
    const currentJobSummaryModel = useAppSelector(store => store.appState.jobSummaryModel);
    const useSuggestedPrompts = useAppSelector(store => store.appState.useSuggestedPrompts);
    const jobPreamble = useAppSelector(store => store.appState.jobSummaryPreamble);
    const jobMessage = useAppSelector(store => store.appState.jobSummaryMessage);
    const debugMode = useAppSelector(store => store.appState.debugMode);

    const currentResumePrompt = useAppSelector(store => store.promptState.resumePrompt);
    const currentResumeNameModel = useAppSelector(store => store.promptState.resumeNameModel);

    const currentInterviewModel = useAppSelector(store => store.promptState.interviewModel);

    const isMobile = useAppSelector(store => store.appState.isMobile);
    const dispatch = useAppDispatch();

    const onChatModelChanged = async (newModel: LLMModel) => {
        dispatch(setChatModel(newModel));
    }

    const onSuggestedPromptsChanged = async () => {
        if (useSuggestedPrompts) {
            dispatch(setUseSuggestedPrompts(false));
        } else {
            dispatch(setUseSuggestedPrompts(true));
        }
    }

    const onDebugModeChanged = async () => {
        if (debugMode) {
            dispatch(setDebugMode(false));
        } else {
            dispatch(setDebugMode(true));
        }
    }

    return (
        <ScrollingWrapper>
            <StyledContainer isMobile={isMobile}>
                <ProfileSectionHeader title='Admin Settings' />

                <SectionTitle top title='Chat Settings' />

                <AdminSettingRow title='Debug Mode' value={
                    <><IOSSwitch checked={debugMode} onChange={onDebugModeChanged} inputProps={{ color: 'red' }} /> {debugMode ? 'Enabled' : 'Disabled'}</>
                } />

                <AdminSettingRow title='Suggested Prompts' value={
                    <><IOSSwitch checked={useSuggestedPrompts} onChange={onSuggestedPromptsChanged} inputProps={{ color: 'red' }} /> {useSuggestedPrompts ? 'Enabled' : 'Disabled'}</>
                } />

                <AdminSettingRow title='Chat Model' value={
                    <Select value={currentChatModel} style={{ borderRadius: '24px', paddingLeft: '12px', paddingRight: '12px' }} >
                        {Object.values(LLMModel).map((model) => {
                            return <MenuItem key={model} value={model} onClick={() => onChatModelChanged(model)}>
                                <Typography variant="body1" style={{ textAlign: 'left', color: AppColors.grey.dark }}>
                                    {model}
                                </Typography>
                            </MenuItem>
                        })}
                    </Select>
                } />

                <AdminSettingRow title='Suggestions Model' value={
                    <Select value={currentSuggModel} style={{ borderRadius: '24px', paddingLeft: '12px', paddingRight: '12px' }} >
                        {Object.values(LLMModel).map((model) => {
                            return <MenuItem key={model} value={model} onClick={() => dispatch(setSuggModel(model))}>
                                <Typography variant="body1" style={{ textAlign: 'left', color: AppColors.grey.dark }}>
                                    {model}
                                </Typography>
                            </MenuItem>
                        })}
                    </Select>
                } />

                <SectionTitle title='Resume Settings' />

                <AdminSettingRow title='Resume Prompt' value={
                    <>
                        <PPMultiLineTextInput id={'resume-prompt-input'} value={currentResumePrompt} onChange={(newMessage) => dispatch(setResumePrompt(newMessage))} maxRows={isMobile ? 10 : 4} style={{ border: '1px #AAA solid', borderRadius: '24px', padding: '10px 12px', width: isMobile ? 'calc(100vw - 60px)' : 'clamp(100px, 500px, 500px)' }} />
                        <ClickableOpacityDiv onClick={() => dispatch(resetResumePrompt())}>Reset</ClickableOpacityDiv>
                    </>
                } />

                <AdminSettingRow title='Resume Naming Model' value={
                    <Select value={currentResumeNameModel} style={{ borderRadius: '24px', paddingLeft: '12px', paddingRight: '12px' }} >
                        {Object.values(LLMModel).map((model) => {
                            return <MenuItem key={model} value={model} onClick={() => dispatch(setResumeModel(model))}>
                                <Typography variant="body1" style={{ textAlign: 'left', color: AppColors.grey.dark }}>
                                    {model}
                                </Typography>
                            </MenuItem>
                        })}
                    </Select>
                } />

                <SectionTitle title='Interview Settings' />

                <AdminSettingRow title='Interview Model' value={
                    <Select value={currentInterviewModel} style={{ borderRadius: '24px', paddingLeft: '12px', paddingRight: '12px' }} >
                        {Object.values(LLMModel).map((model) => {
                            return <MenuItem key={model} value={model} onClick={() => dispatch(setInterviewModel(model))}>
                                <Typography variant="body1" style={{ textAlign: 'left', color: AppColors.grey.dark }}>
                                    {model}
                                </Typography>
                            </MenuItem>
                        })}
                    </Select>
                } />

                <SectionTitle title='Job Summary Settings' />

                <AdminSettingRow title='Job Summary Model' value={
                    <Select value={currentJobSummaryModel} style={{ borderRadius: '24px', paddingLeft: '12px', paddingRight: '12px' }} >
                        {Object.values(LLMModel).map((model) => {
                            return <MenuItem key={model} value={model} onClick={() => dispatch(setJobSummaryModel(model))}>
                                <Typography variant="body1" style={{ textAlign: 'left', color: AppColors.grey.dark }}>
                                    {model}
                                </Typography>
                            </MenuItem>
                        })}
                    </Select>
                } />

                <AdminSettingRow title='Job Summary Preamble' value={
                    <>
                        <PPMultiLineTextInput id={'job-preamble-input'} value={jobPreamble} onChange={(newPreamble) => dispatch(setJobSummaryPreamble(newPreamble))} maxRows={isMobile ? 10 : 4} style={{ border: '1px #AAA solid', borderRadius: '24px', padding: '10px 12px', width: isMobile ? 'calc(100vw - 60px)' : 'clamp(100px, 500px, 500px)' }} />
                        <ClickableOpacityDiv onClick={() => dispatch(resetJobSummaryPreamble())}>Reset</ClickableOpacityDiv>
                    </>
                } />

                <AdminSettingRow title='Job Summary Message' value={
                    <>
                        <PPMultiLineTextInput id={'job-preamble-input'} value={jobMessage} onChange={(newMessage) => dispatch(setJobSummaryMessage(newMessage))} maxRows={isMobile ? 10 : 4} style={{ border: '1px #AAA solid', borderRadius: '24px', padding: '10px 12px', width: isMobile ? 'calc(100vw - 60px)' : 'clamp(100px, 500px, 500px)' }} />
                        <ClickableOpacityDiv onClick={() => dispatch(resetJobSummaryMessage())}>Reset</ClickableOpacityDiv>
                    </>
                } />

                <SectionTitle title='Info' />
                <AdminSettingRow title='Version' value={packageJson.version} />

                <div style={{ minHeight: '20px', maxHeight: '20px', }} />
            </StyledContainer>
        </ScrollingWrapper>
    )
}

export default AdminSettingsPage;